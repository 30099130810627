<script setup>
import { defineProps, defineEmits, watch, ref, inject, computed } from "vue";

// *Outras importações *
import { toast } from "vue3-toastify";

// *Componentes *
import TagInput from "../common/TagInput.vue";
import axios from "axios";

const {
  currentTotalMessages,
  selectedStoredMails,
  currentPageEmailIds,
  updateMails,
  currentFilter,
  fetchEmails,
  getMessageById,
  archiveMessage,
  inbox,
} = inject("inbox");

const { mails, calculateIndexes, nextPage, previousPage, filterEmails } =
  defineProps([
    "mails",
    "calculateIndexes",
    "startIndex",
    "endIndex",
    "nextPage",
    "previousPage",
    "filterEmails",
  ]);
const emit = defineEmits();

const selectedAll = ref(false);

const toggleSelectAll = (ids) => {
  if (selectedAll.value === true)
    selectedStoredMails.value = selectedStoredMails.value.filter(
      (emailId) => !ids.includes(emailId)
    );

  if (selectedAll.value === false) selectedStoredMails.value.push(...ids);

  selectedAll.value = !selectedAll.value;
};

const options = computed(() => [
  {
    label: "Arquivar",
    key: "ARCHIVE",
    disabled:
      currentFilter.value === "Arquivados" ||
      currentFilter.value === "[Gmail]/E-mails enviados" ||
      currentFilter.value === "Sent",
  },
  {
    label: "Excluir",
    key: "DELETE",
    disabled: true,
  },
  {
    label: "Marcar como lida",
    key: "READ_EMAIL",
    disabled: true,
  },
  {
    label: "Mover para",
    key: "MOVE",
    disabled: true,
  },
]);

const handleSelect = async (key) => {
  switch (key) {
    case "ARCHIVE":
      try {
        const uids = [];
        let path;

        const id = toast.loading("Arquivando e-mail(s)...", {
          position: "top-center",
          toastStyle: {
            fontFamily: "Fivo Sans Modern Regular",
          },
        });

        for (const emailId of selectedStoredMails.value) {
          const data = await getMessageById(emailId);

          path = data.inbox_origin;
          uids.push(data.uid);
        }

        if (uids.length > 0) {
          await archiveMessage(path, uids);

          updateMails(selectedStoredMails.value);

          toast.update(id, {
            render: "E-mail(s) arquivado(s)!",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            toastStyle: {
              fontFamily: "Fivo Sans Modern Regular",
            },
            progressStyle: {
              backgroundColor: "green",
            },
          });

          selectedStoredMails.value.length = 0;
        }
      } catch (error) {
        toast("Oops! Erro ao arquivar e-mail(s)!", {
          position: "top-center",
          autoClose: 5000,
          type: "error",
          toastStyle: {
            "--toastify-icon-color-error": "#c60d31",
            "--toastify-color-error": "#c60d31",
            fontFamily: "Fivo Sans Modern Regular",
          },
          progressStyle: {
            backgroundColor: "#c60d31",
          },
        });
        console.error(error);
      }
  }
};

const archiveAndSeeAllAutoreplys = async () => {
  const inboxPath = currentFilter.value;
  const id = inbox.value.id;

  console.log(id, inboxPath);
  let toastID;

  try {
    toastID = toast.loading("Arquivando e visualizando e-mail(s)...", {
      position: "top-center",
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });

    const response = await axios.get(
      `https://apiemails.flashgrowth.com.br/email-reader/archive-and-read-all-auto-reply/${id}?path=${inboxPath}`,
      {
        headers: {
          Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13",
        },
      }
    );

    toast.update(toastID, {
      render: "E-mail(s) arquivado(s) e visualizado(s)!",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "green",
      },
    });

    console.log(response);
  } catch (error) {
    toast.update(toastID, {
      render: "Oops! Erro ao arquivar e visualizar e-mail(s)!",
      type: "error",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "#c60d31",
      },
    });
    console.error(error);
  }
};

const tags = ref("");

const inputTag = (tag) => {
  tags.value = tag;
};

watch(
  () => tags.value,
  async (newTags) => {
    if (Object.keys(newTags).length > 0) {
      console.log("Tags atualizadas: ", newTags);
      await filterEmails(newTags);
      calculateIndexes();
    } else {
      await fetchEmails(currentFilter.value);
      calculateIndexes();
    }
  },
  { deep: true }
);

watch(
  () => currentPageEmailIds.value,
  () => {
    if (!currentPageEmailIds.value.length) {
      selectedAll.value = false;
      return;
    }

    const allSelected = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    selectedAll.value = allSelected;
  },
  { immediate: true }
);

watch(
  () => selectedStoredMails.value.length,
  () => {
    const storedCondition = currentPageEmailIds.value.some((id) =>
      selectedStoredMails.value.includes(id)
    );

    if (selectedStoredMails.value.length !== 0 && storedCondition) {
      selectedAll.value = true;
    }

    if (storedCondition === false) {
      selectedAll.value = false;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="mailbox-header">
    <div class="mailbox-search">
      <TagInput class="form-control" @input="inputTag" />
    </div>
  </div>

  <div class="mailbox-option">
    <div
      class="mailbox-select"
      @click="toggleSelectAll(mails.map((item) => item.id))"
    >
      <n-checkbox size="large" :checked="selectedAll" />
    </div>
    <div class="selected-options">
      <n-dropdown trigger="click" :options="options" @select="handleSelect">
        <svg
          style="outline: none; box-shadow: none"
          viewBox="0 0 24 24"
          fill="none"
          height="15px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>

          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
              fill="#C0C0C0"
            ></path>
          </g>
        </svg>
      </n-dropdown>
    </div>
    <svg
      @click="archiveAndSeeAllAutoreplys"
      class="archive-and-see-all-autoreplys"
      viewBox="0 0 24 24"
      version="1.1"
      height="18px"
      xmlns="htt p://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <title>inbox_fill</title>
        <g
          id="页面-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="File" transform="translate(-624.000000, -48.000000)">
            <g id="inbox_fill" transform="translate(624.000000, 48.000000)">
              <path
                d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                id="MingCute"
                fill-rule="nonzero"
              ></path>
              <path
                d="M5.82918,5.10557 C6.16796,4.428 6.86049,4 7.61803,4 L16.382,4 C17.1395,4 17.832,4.428 18.1708,5.10557 L21.6833,12.1305 C21.8916,12.5471 22,13.0064 22,13.4721 L22,19 C22,20.1046 21.1046,21 20,21 L4,21 C2.89543,21 2,20.1046 2,19 L2,13.4721 C2,13.0064 2.10844,12.5471 2.31672,12.1305 L5.82918,5.10557 Z M16.382,6 L7.61803,6 L4.12,13 L7.5,13 C8.32843,13 9,13.6716 9,14.5 L9,15.5 C9,15.7761 9.22386,16 9.5,16 L14.5,16 C14.7761,16 15,15.7761 15,15.5 L15,14.5 C15,13.6716 15.6716,13 16.5,13 L19.88,13 L16.382,6 Z"
                id="形状"
                fill="#ec0909"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <span v-if="selectedStoredMails.length > 0" class="selected-amount">{{
      `${selectedStoredMails.length} de ${currentTotalMessages}`
    }}</span>
    <div class="ms-auto d-flex align-items-center">
      {{ startIndex - 15 < 1 ? 1 : startIndex - 15 }}-{{
        currentTotalMessages < 15 ? currentTotalMessages : endIndex
      }}
      de
      {{ currentTotalMessages }}
      <a
        class="btn pager prev"
        @click="previousPage"
        :class="{ disabled: startIndex <= 1 }"
      >
        <i class="ri-arrow-left-s-line"></i>
      </a>
      <a
        class="btn pager next"
        @click="nextPage(currentTotalMessages)"
        :class="{ disabled: endIndex >= currentTotalMessages }"
      >
        <i class="ri-arrow-right-s-line"></i>
      </a>
    </div>
  </div>
</template>
<style scoped>
.mailbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: all 0.3s;
  scrollbar-width: thin;
  scrollbar-color: #f5f6fa #dde1ed;
  overflow-y: auto;
  z-index: 5;
}

@media (prefers-reduced-motion: reduce) {
  .mailbox {
    transition: none;
  }
}

@media (min-width: 768px) {
  .mailbox {
    left: 240px;
    width: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .mailbox {
    width: 390px;
    right: auto;
  }
}

.loader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mailbox-header {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
}

@media (min-width: 768px) {
  .mailbox-header {
    padding: 20px 20px 10px;
  }
}

.mailbox-header .mail-avatar {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
  display: block;
  text-decoration: none;
}

@media (min-width: 768px) {
  .mailbox-header .mail-avatar {
    top: 28px;
    right: 30px;
  }
}

.mailbox-header .mail-avatar span {
  background-color: #dc0f37;
  font-size: 14px;
}

.mailbox-menu {
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 10px;
  text-decoration: none;
  text-indent: -2px;
  color: #343740;
}

@media (min-width: 768px) {
  .mailbox-menu {
    margin-left: 0;
    margin-right: 15px;
  }
}

.mailbox-menu:hover,
.mailbox-menu:focus {
  color: #dc0f37;
}

.mailbox-menu i {
  font-size: 24px;
  line-height: 1;
}

.mailbox-search {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dde1ed;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25),
    0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15),
    0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
}

.mailbox-search i {
  font-size: 20px;
  line-height: 1;
  color: #6b748f;
  margin-top: -2px;
}

.mailbox-search .form-control {
  flex: 1;
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 5px;
}

.mailbox-search .form-control:focus {
  box-shadow: none;
}

.mailbox-search .form-control::placeholder {
  color: #a1a8bb;
  text-shadow: none;
}

.mailbox-option {
  padding: 5px 10px 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
}

@media (min-width: 768px) {
  .mailbox-option {
    padding: 5px 20px 15px;
  }
}

.mailbox-option span {
  margin-right: 10px;
  color: #a1a8bb;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.mailbox-option .pager {
  position: relative;
  width: 28px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #cccfd9;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #6b748f;
  text-decoration: none;
  box-shadow: 0 1px 1px rgba(233, 234, 240, 0.25),
    0 2px 2px rgba(233, 234, 240, 0.2), 0 4px 4px rgba(233, 234, 240, 0.15),
    0 8px 8px rgba(233, 234, 240, 0.1), 0 16px 16px rgba(233, 234, 240, 0.05);
  cursor: pointer;
  margin-left: 5px;
}

.mailbox-option .pager:hover,
.mailbox-option .pager:focus {
  background-color: #e9ecf3;
}

.mailbox-option .pager i {
  font-size: 22px;
  line-height: 1;
}

.mailbox-option .pager + .pager {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.mailbox-select {
  display: flex;
  margin-left: 10px;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(233, 234, 240, 0.25),
    0 2px 2px rgba(233, 234, 240, 0.2), 0 4px 4px rgba(233, 234, 240, 0.15),
    0 8px 8px rgba(233, 234, 240, 0.1), 0 16px 16px rgba(233, 234, 240, 0.05);
}

.mailbox-select .dropdown-check {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccfd9;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  color: #6b748f;
  text-decoration: none;
}

.mailbox-select .dropdown-check:hover,
.mailbox-select .dropdown-check:focus {
  background-color: #f0f2f7;
}

.mailbox-select .dropdown-check i {
  font-size: 18px;
  line-height: 1;
  border-radius: 2px;
}

.mailbox-select .dropdown-check i:not(:first-child) {
  display: none;
}

.mailbox-select .dropdown-check.checkall i {
  color: #dc0f37;
}

.mailbox-select .dropdown-check.checkall i:first-child {
  display: none;
}

.mailbox-select .dropdown-check.checkall i:nth-child(2) {
  display: block;
}

.mailbox-select .dropdown-check.checkall i:last-child {
  display: none;
}

.mailbox-select .dropdown-check.check i {
  color: #dc0f37;
}

.mailbox-select .dropdown-check.check i:first-child,
.mailbox-select .dropdown-check.check i:nth-child(2) {
  display: none;
}

.mailbox-select .dropdown-check.check i:last-child {
  display: block;
}

.mailbox-select .dropdown-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 32px;
  text-decoration: none;
  color: #6b748f;
  font-size: 18px;
  border: 1px solid #cccfd9;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  margin-left: -1px;
}

.mailbox-select .dropdown-link:hover,
.mailbox-select .dropdown-link:focus {
  background-color: #f0f2f7;
}

.mailbox-select .dropdown-menu {
  margin-top: 2px !important;
  width: 180px;
  padding: 5px;
  border-color: rgba(33, 35, 41, 0.12);
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(204, 207, 217, 0.08),
    0 2px 2px rgba(204, 207, 217, 0.12), 0 4px 4px rgba(204, 207, 217, 0.16),
    0 8px 8px rgba(204, 207, 217, 0.2);
}

.mailbox-select .dropdown-item {
  font-size: 14px;
  color: #212329;
  padding: 6px 8px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.mailbox-select .dropdown-item + .dropdown-item {
  margin-top: 1px;
}

.mailbox-select .dropdown-item:hover {
  background-color: #f5f6fa;
}

.mailbox-select .dropdown-item i {
  font-size: 16px;
  line-height: 1.2px;
  margin-right: 7px;
  opacity: 0;
}

.mailbox-select .dropdown-item.active {
  background-color: #edf1fe;
  color: #dc0f37;
}

.mailbox-select .dropdown-item.active i {
  opacity: 1;
}

.mail-group {
  position: relative;
  padding: 0 10px;
  margin: 0;
  list-style: none;
  height: calc(100vh - 115px);
  overflow-y: auto;
}

@media (min-width: 768px) {
  .mail-group {
    padding: 0 20px;
    height: calc(100vh - 130px);
  }
}

.mail-item {
  position: relative;
  min-height: 100px;
  border-radius: 5px;
  padding: 10px 10px 12px;
  background-color: #f5f6fa;
  border: 1px solid transparent;
  display: flex;
  overflow: hidden;
}

.mail-item:last-of-type {
  margin-bottom: 10px;
}

.mail-item + .mail-item {
  margin-top: 2px;
}

.mail-item:hover,
.mail-item:focus {
  cursor: pointer;
  z-index: 6;
  border-color: rgba(118, 149, 245, 0.6);
  background-color: rgba(245, 246, 250, 0.6);
}

.mail-item.unread {
  background-color: #fff;
}

.mail-item.unread .mail-sender,
.mail-item.unread .mail-time {
  font-weight: 500;
  color: #212329;
}

.mail-item.unread .mail-subject {
  font-weight: 500;
  color: #212329;
}

@media (min-width: 768px) {
  .mail-item.active {
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.75);
    border-color: #7695f5;
    box-shadow: 0 1px 1px rgba(204, 207, 217, 0.25),
      0 2px 2px rgba(204, 207, 217, 0.2), 0 4px 4px rgba(204, 207, 217, 0.15),
      0 8px 8px rgba(204, 207, 217, 0.1), 0 16px 16px rgba(204, 207, 217, 0.05);
  }
}

.mail-item.selected {
  background-color: #e8edfd;
}

.mail-avatar {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.mail-avatar img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.mail-item-body {
  flex: 1;
  padding-left: 15px;
}

.mail-item-body > div:first-child {
  margin-bottom: 2px;
}

.mail-sender {
  font-size: 12px;
  margin-right: auto;
}

.mail-time {
  font-size: 11px;
  color: #a1a8bb;
  margin-right: 25px;
}

.mail-star:hover,
.mail-star:focus {
  color: #ffc107;
}

.mail-star i:last-child {
  display: none;
}

.mail-star.active {
  color: #ffc107;
}

.mail-star.active i:first-child {
  display: none;
}

.mail-star.active i:last-child {
  display: inline;
}

.mail-subject {
  font-size: 14px;
  font-weight: 400;
  color: #212329;
  margin-bottom: 5px;
}

.mail-subject span {
  font-weight: 300;
  color: #a1a8bb;
}

.mail-text {
  margin-bottom: 0;
  color: #6b748f;
  font-size: 13px;
}

.mail-star {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  top: 0.5px;
  line-height: 1;
}

.mail-new {
  height: 9px;
  width: 9px;
}

.ri-star-line,
.ri-star-fill {
  font-size: 13px;
}

.tag {
  color: white;
  padding: 3px;
  margin: 3px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  float: left;
}
.tag span {
  padding: 0px 5px;
  font-size: 12px;
}

.presentation {
  background-color: rgb(255 0 174 / 50%);
}

.referral {
  background-color: rgba(0, 38, 255, 0.5);
}

.mailing_exclusion {
  background-color: rgba(255, 0, 0, 0.5);
}

.prospecting {
  background-color: rgba(255, 145, 0, 0.5);
}

.scheduling {
  background-color: rgba(0, 255, 0, 0.5);
}

.no_interest {
  background-color: rgba(128, 128, 128, 1);
}

.selected-options {
  cursor: pointer;
  display: flex;
  margin-left: 6px;
}

.selected-amount {
  margin-left: 10px;
}

.empty-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 243px;
}

.empty-upper-text-msg {
  color: #919eab;
  font-weight: 600;
  line-height: 1.55556;
  text-align: center;
  margin: 8px 0px 0px;
  font-size: 1.0625rem;
}

.empty-lower-text-msg {
  margin: 8px 0px 0px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: center;
  color: #919eab;
}

.archive-and-see-all-autoreplys {
  margin-left: 10px;
  cursor: pointer;
}
</style>
