import axios from "axios";

export const sendGrid = async (selectedCategory, startDate, endDate) => {
  const response = await axios.get(
    "https://api.sendgrid.com/v3/categories/stats",
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer SG.e4eTPX0JQ4ObTQPPCC5Gww.0D556FP7QuziCPpK5PzSs_muVG1jef-X_eCu2OzCAfs",
      },
      params: {
        categories: selectedCategory,
        start_date: startDate,
        end_date: endDate,
        aggregated_by: "day",
      },
    }
  );

  return response.data;
};

export const sendGridGeneral = async (
  selectedMonth,
  shortYear,
  startDate,
  endDate
) => {
  const response = await axios.get(
    "https://api.sendgrid.com/v3/categories/stats",
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Bearer SG.e4eTPX0JQ4ObTQPPCC5Gww.0D556FP7QuziCPpK5PzSs_muVG1jef-X_eCu2OzCAfs",
      },
      params: {
        categories: `${selectedMonth}-${shortYear}`,
        start_date: startDate,
        end_date: endDate,
        aggregated_by: "day",
      },
    }
  );

  return response.data;
};
