// *Importações do VUE.JS*
import { ref, computed, reactive } from "vue";
import router from "../../../router";
import axios from "axios";
import config from "../../../../config/environment.js";

// *Serviços*
import { FetchAPI } from "@/modules/inbox/providers/api/get-emails";
const api = new FetchAPI(config.GROWTH_DB);
const apiEmails = new FetchApiEmails(config.API_EMAILS);

// *Helpers*
import { formatTime } from "../helpers/helpers";
import { FetchApiEmails } from "../providers/api/api-emails";

// *Hooks*
export const useInbox = (currentInbox) => {
  const current_inbox = ref(currentInbox || null);

  const inbox = ref({});
  const mails = ref([]);
  const inboxBusinessClients = ref([]);
  const inboxBusinessUserMail = ref("");
  const inboxBusinessUserInitial = computed({
    get() {
      return inbox.value != undefined
        ? inbox.value.details.user.charAt(0)
        : "F";
    },
  });
  const metaInfo = reactive([]);

  const inboxMessage = reactive({});
  const currentPage = ref(1);
  const startIndex = ref(0);
  const endIndex = ref(0);
  const pageSize = 15;
  const currentTotalMessages = ref(0);
  const currentFilter = ref("INBOX");
  const selectedMail = ref("");
  const batonPassEmails = computed({
    get() {
      return inbox.value != undefined ? inbox.value.baton_pass : null;
    },
  });
  const emailBody = ref({
    type: "",
    to: "",
    subject: "",
    html: "",
    text: "",
    messageId: "",
    references: "",
    cc: "",
    bcc: "",
  });

  const selectedStoredMails = ref([]);
  const currentPageEmailIds = computed(() => {
    return mails.value.map((email) => email.id);
  });

  const inboxUserStatus = ref(false);
  const mailContent = ref("");

  const updateInbox = (inbox) => {
    inbox.value = inbox.target.value;
  };

  const updateMails = (filteringIds) => {
    mails.value = mails.value.filter((mail) => {
      return !filteringIds.includes(mail.id);
    });

    calculateIndexes();
    fetchEmails(currentFilter.value);
  };

  const ready = ref(true);
  const isSearching = ref(false);
  const searchingContent = ref("");
  const intentText = ref(null);

  const openWitAi = ref(false);
  const intentsWitAi = ref(null);
  const messageContent = ref(null);

  let roleCommand = ref("");
  const isOpenHandleCompose = ref(false);

  const signatures = computed(() => {
    return inbox.value.signatures;
  });

  const fetchMetaInfo = async () => {
    const response = await axios.get(
      `${config.GROWTH_DB}/inbox-business-clients/${inbox.value.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13",
        },
      }
    );

    const data = response.data.data.meta_info;

    metaInfo.length = 0;
    metaInfo.push(data);
  };

  const getInboxBusinessClientEmails = async () => {
    const response = await api.getAllInboxBusinessClients();
    /*response.data.forEach((email) => {
      inboxBusinessClients.value.push(email);
    });*/

    const filteredEmails = response.data.filter(
      (email) => email.status === true
    );

    inboxBusinessClients.value.push(...filteredEmails);

    if (current_inbox.value) {
      const findInbox = inboxBusinessClients.value.find(
        (ibc) => ibc.id == current_inbox.value
      );
      if (!findInbox) current_inbox.value = inboxBusinessClients.value[0].id;
    } else current_inbox.value = inboxBusinessClients.value[0].id;
  };

  const getInboxBusinessClientById = async () => {
    const response = await api.getInboxBusinessClientById(current_inbox.value);
    inboxBusinessUserMail.value = response.data.details.user;
    inbox.value = response.data;
    return response;
  };

  const filterEmails = async (searchedContent) => {
    selectedMail.value = null;
    ready.value = false;
    mails.value.length = 0;

    isSearching.value = true;
    searchingContent.value = searchedContent;

    try {
      const response = await api.getAllFilteredEmailsPaginated(
        {
          searched_content: searchingContent.value,
        },
        inbox.value.id !== undefined ? inbox.value.id : currentInbox,
        currentPage.value,
        pageSize
      );
      const { rows, totalSearched } = response.data;

      currentTotalMessages.value = totalSearched;

      mails.value = rows.map((email) => ({
        messageId: email.message_id,
        uid: email.uid,
        id: email.id,
        avatar: email.from[0].name.charAt(0),
        from: email.from[0].name,
        fromAddress: email.from[0].address,
        to: email.to[0].name,
        toAddress: email.to[0].address,
        cc: email.cc,
        bcc: email.bcc,
        subject: email.subject,
        content: email.content_text,
        content_html: email.content_html,
        intentions: email.intentions,
        intentionText: email.intention_text,
        inboxOrigin: email.inbox_origin,
        date: formatTime(email.date),
        mark_seen: email.mark_seen,
        references: JSON.parse(email.references),
        inbox_business_client_id: email.inbox_business_client_id,
      }));

      ready.value = true;
    } catch (error) {
      console.error("Error fetching emails: ", error);
    }
  };

  const fetchEmails = async (openedInbox) => {
    selectedMail.value = null;
    ready.value = false;
    mails.value.length = 0;

    isSearching.value = false;
    searchingContent.value = "";

    try {
      let response;

      //* <---Gambiarra para pegar o filtro correto *

      if (
        openedInbox === "INBOX" ||
        openedInbox === "SENT" ||
        openedInbox === "ARCHIVE" ||
        openedInbox === "SPAM"
      ) {
        if (inbox.value.id !== 6 && openedInbox === "Sent")
          openedInbox = "SENT";

        if (inbox.value.provider_name === "locaweb" && openedInbox === "Sent")
          openedInbox = "SENT";

        if (
          inbox.value.provider_name === "locaweb" &&
          openedInbox === "ARCHIVE"
        )
          openedInbox = "ARCHIVE";

        if (inbox.value.id !== 6 && openedInbox === "Junk")
          openedInbox = "SPAM";

        if (inbox.value.provider_name === "titan" && openedInbox === "ARCHIVE")
          openedInbox = "ARCHIVE";

        currentFilter.value = openedInbox;
        response = await api.getAllEmailsPaginated(
          inbox.value.id !== undefined ? inbox.value.id : currentInbox,
          currentPage.value,
          pageSize,
          openedInbox
        );
      }

      if (
        openedInbox !== "INBOX" &&
        openedInbox !== "SENT" &&
        openedInbox !== "SPAM" &&
        openedInbox !== "ARCHIVE"
      ) {
        const intentionLabel = openedInbox;

        response = await api.getAllEmailsPaginated(
          inbox.value.id !== undefined ? inbox.value.id : currentInbox,
          currentPage.value,
          pageSize,
          null,
          intentionLabel
        );
      }
      //* Gambiarra para pegar o filtro correto ---> *

      const { totalEmails, boxes, rows, intentions } = response.data;

      inboxMessage.totalEmails = totalEmails;
      inboxMessage.inbox = boxes.inbox;
      inboxMessage.sent = boxes.sent;
      inboxMessage.archived_count = boxes.archived;
      inboxMessage.spam = boxes.spam;
      inboxMessage.intentions = intentions;

      //*======================= E-mails não lidos ================================= */
      inboxMessage.unReadInbox = boxes.unRead.inbox;
      inboxMessage.unReadSent = boxes.unRead.sent;
      inboxMessage.unReadArchived = boxes.unRead.archived;
      inboxMessage.unReadSpam = boxes.unRead.spam;

      inboxMessage.unReadScheduling = intentions.unRead.scheduling_intention;
      inboxMessage.unReadReferral = intentions.unRead.referral_intention;
      inboxMessage.unReadPresentation =
        intentions.unRead.presentation_intention;
      inboxMessage.unReadNoInterest = intentions.unRead.no_interest;
      inboxMessage.unReadProspecting = intentions.unRead.prospecting_intention;
      inboxMessage.unReadMailingExclusion = intentions.unRead.mailingExclusion;
      inboxMessage.unReadAutoReply = intentions.unRead.autoReply;

      //*======================= Total de e-mails da caixa atual ================================= */
      if (openedInbox === "INBOX")
        currentTotalMessages.value = inboxMessage.inbox;

      if (openedInbox === "SENT")
        currentTotalMessages.value = inboxMessage.sent;

      if (openedInbox === "ARCHIVE")
        currentTotalMessages.value = inboxMessage.archived_count;

      if (openedInbox === "SPAM")
        currentTotalMessages.value = inboxMessage.spam;

      if (openedInbox === "Scheduling")
        currentTotalMessages.value = inboxMessage.intentions.scheduling;

      if (openedInbox === "Referral")
        currentTotalMessages.value = inboxMessage.intentions.referral;

      if (openedInbox === "Presentation")
        currentTotalMessages.value = inboxMessage.intentions.presentation;

      if (openedInbox === "Prospecting")
        currentTotalMessages.value = inboxMessage.intentions.prospecting;

      if (openedInbox === "Mailing_exclusion")
        currentTotalMessages.value = inboxMessage.intentions.mailingExclusion;

      if (openedInbox === "auto_reply")
        currentTotalMessages.value = inboxMessage.intentions.autoReply;

      if (openedInbox === "no_interest")
        currentTotalMessages.value = inboxMessage.intentions.no_interest;

      mails.value = rows.map((email) => ({
        messageId: email.message_id,
        uid: email.uid,
        id: email.id,
        avatar: email.from[0].name.charAt(0),
        from: email.from[0].name,
        fromAddress: email.from[0].address,
        to: email.to ? email.to[0].name : null,
        toAddress: email.to ? email.to[0].address : null,
        cc: email.cc,
        bcc: email.bcc,
        subject: email.subject,
        content: email.content_text,
        content_html: email.content_html,
        intentions: email.intentions,
        intentionText: email.intention_text,
        inboxOrigin: email.inbox_origin,
        date: formatTime(email.date),
        mark_seen: email.mark_seen,
        references: JSON.parse(email.references),
        inbox_business_client_id: email.inbox_business_client_id,
      }));

      ready.value = true;
    } catch (error) {
      console.error("Error fetching emails: ", error);
    }

    calculateIndexes();
  };

  const handlePageSelected = (content) => {
    currentPage.value = 1;
    currentFilter.value = content;
    calculateIndexes();

    fetchEmails(content);
  };

  const calculateIndexes = () => {
    startIndex.value =
      currentPage.value === 1 ? 1 : currentPage.value * pageSize;

    const verifiedValue = currentPage.value * pageSize;
    endIndex.value =
      verifiedValue > currentTotalMessages.value
        ? currentTotalMessages.value
        : verifiedValue;
  };

  const nextPage = async (items) => {
    const totalPages = Math.ceil(
      (currentTotalMessages.value || items) / pageSize
    );
    if (currentPage.value < totalPages) {
      currentPage.value++;
      router.push({
        path: `/inbox/${current_inbox.value}/messages`,
        query: { p: currentPage.value },
      });
      calculateIndexes();
      if (isSearching.value) filterEmails(searchingContent.value);
      if (!isSearching.value) fetchEmails(currentFilter.value);
    }
  };

  const previousPage = async () => {
    if (currentPage.value >= 0) {
      currentPage.value--;

      if (currentPage.value === 1) {
        router.push({
          path: `/inbox/${current_inbox.value}/messages`,
        });
      } else {
        router.push({
          path: `/inbox/${current_inbox.value}/messages`,
          query: { p: currentPage.value },
        });
      }

      calculateIndexes();
      if (isSearching.value) filterEmails(searchingContent.value);
      if (!isSearching.value) fetchEmails(currentFilter.value);
    }
  };

  const openOpportunity = ref(false);

  const getMessageContent = async (messageID) => {
    return await api.getMessage(inbox.value.id, messageID);
  };

  const getEmailMetrics = async (id, startDate, endDate) => {
    return await api.getEmailMetrics(id, startDate, endDate);
  };

  const getMailboxMetadata = async (id = inbox.value.id) => {
    return await api.getMailboxMetadata(id);
  };

  const getMessageById = async (emailID) => {
    return await api.getMessageById(emailID);
  };

  const archiveMessage = async (path, uids) => {
    return await apiEmails.archiveMessage(inbox.value.id, path, uids);
  };

  const updateMessageContent = async (messageContentID, data) => {
    return await apiEmails.updateMessageContent(messageContentID, data);
  };

  const readMessage = async (uid, boxPath) => {
    return await apiEmails.readMessage(inbox.value.id, uid, boxPath);
  };

  const sendMessage = async (messageForm) => {
    return await apiEmails.sendMessage(messageForm);
  };

  const replyMessage = async (messageForm) => {
    return await apiEmails.replyMessage(messageForm);
  };

  return {
    current_inbox,
    mailContent,
    mails,
    inboxBusinessClients,
    inboxBusinessUserMail,
    inboxBusinessUserInitial,
    currentPage,
    calculateIndexes,
    startIndex,
    endIndex,
    nextPage,
    currentFilter,
    selectedMail,
    fetchEmails,
    previousPage,
    fetchEmails,
    ready,
    handlePageSelected,
    getInboxBusinessClientEmails,
    getInboxBusinessClientById,
    inbox,
    updateInbox,
    emailBody,
    filterEmails,
    fetchMetaInfo,
    metaInfo,
    inboxMessage,
    inboxUserStatus,
    currentTotalMessages,
    selectedStoredMails,
    currentPageEmailIds,
    updateMails,
    signatures,
    batonPassEmails,
    intentText,
    openWitAi,
    intentsWitAi,
    messageContent,
    roleCommand,
    isOpenHandleCompose,
    getMessageContent,
    getEmailMetrics,
    getMailboxMetadata,
    getMessageById,
    archiveMessage,
    updateMessageContent,
    readMessage,
    sendMessage,
    replyMessage,
    openOpportunity,
  };
};
